#overflow-container {
    text-align: center;
    overflow-x: auto;
}

.tooltipChoroplethMap {
    font-family: 'Ancizar sans';
    position: absolute;
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.6);
    border: 0px;
    border-radius: 5px;
    pointer-events: none;
}

.hoverDecoration {
    cursor: pointer;
    &:hover {
        text-decoration: underline !important;
    }
}

.pointer {
    cursor: pointer !important;
}

.language {
    color: #fff !important;
}

/* ul.collapse-menu {
	background: #333; border-color: #222; padding: 0;
} */

.collapse-item{ padding: 5px 15px!important; }

/* ul.collapse-menu a {
	color: white; padding: 3px 10px;
}

ul.collapse-menu a:hover,
ul.collapse-menu a:active,
ul.collapse-menu a:focus {
	background: #4b4b4b; color: white;
} */

#pestania-accesibilidad-mobil {
    float: right;
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.8);
    color: #fff;
    height: 35px;
    line-height: 35px;
    margin-right: 40px;
    font-weight: bold;
    padding: 0 10px;
    position: relative
}

#pestania-accesibilidad-mobil::before {
    position: absolute;
    left: -35px;
    top: 0;
    content: '';
    background-image: url(../images/unal/access-icon.jpg);
    width: 35px;
    height: 35px
}

@media(max-width: 767px) {
    #pestania-accesibilidad-mobil {
        position: absolute;
        color: transparent;
        top: 11px;
        left: auto;
        right: 43px;
        width: 10px;
        background: transparent;
    }


    #pestania-accesibilidad-mobil::before {
        position: absolute;
        left: -35px;
        top: 0;
        content: '';
        background-image: url(../images/unal/access-icon.png);
        width: 35px;
        height: 35px;
    }
}