@import url('accesibilidad.css');
@import url('base.css');
@import url('bootstrap-theme.min.css');
@import url('bootstrap.min.css');
@import url('frontend.css');
@import url('printer.css');
@import url('reset.css');
@import url('unal.css');

.text-container {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    text-overflow: ellipsis;
    white-space: normal;
    transition: max-height 0.3s ease;
}

.text-container:hover {
    max-height: none;
    overflow: visible;
    -webkit-line-clamp: 1000;
}